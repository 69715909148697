import React, { useEffect } from "react";
import { styled, keyframes } from "styled-components";
import { Link } from "@remix-run/react";
import { useAppContext } from "~/utils/app-content-provider"; // Add this import
import { MdOutlineNavigateNext } from "react-icons/md";
import { BiSolidMessageDetail } from "react-icons/bi";

interface MessageSnackbarProps {
  profileImage: string;
  profileName: string;
  textSnippet: string;
  conversationId: string;
  isVisible: boolean;
  onClose: () => void;
  isMobile: boolean | undefined;
}

const slideIn = keyframes`
  0% {
    transform: translateX(-150%); /* Start way off the screen */
    opacity: 1;
  }
  80% {
    transform: translateX(-50%); /* Overshoot a bit */
    opacity: 1;
  }
  100% {
    transform: translateX(-50%); /* Center the element */
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(-50%); /* Start at the centered position */
    opacity: 1;
  }
  20% {
    transform: translateX(-50%);
    opacity: 1;
  }
  100% {
    transform: translateX(150%); /* Move out of the screen to the right */
    opacity: 1;
  }
`;

const SnackbarContainer = styled.div<{
  $isVisible: boolean;
  $isMobile: boolean | undefined;
  $theme: any;
}>`
  position: fixed;
  top: ${({ $isMobile }) => ($isMobile ? "69px" : "40px")};
  left: 50%;
  transform: translateX(-50%);
  width: ${({ $isMobile }) => ($isMobile ? "90%" : "30%")};
  background-color: ${({ $isMobile, $theme }) => $isMobile ? $theme.colors.messageIn : 'white'};
  color: ${({ $isMobile, $theme }) => $isMobile ? $theme.colors.text : 'black'};
  border: 1px solid ${({ $isMobile, $theme }) => $isMobile ? $theme.colors.border : '#e0e0e0'};
  padding: ${({ $theme }) => $theme.padding.small};
  border-radius: ${({ $theme }) => $theme.borderRadius.medium};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  z-index: 1000;
  animation: ${({ $isVisible }) => ($isVisible ? slideIn : slideOut)} 0.8s
    cubic-bezier(0.25, 1, 0.5, 1) forwards;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
`;

const ProfileName = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MessageContent = styled.div`
  flex: 1;
`;

const TextSnippet = styled.p`
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`;

const MessageIcon = styled(BiSolidMessageDetail)`
  font-size: 20px;
  margin-right: 8px;
`;

const ArrowIcon = styled(MdOutlineNavigateNext)`
  font-size: 24px;
`;

export default function MessageSnackbar({
  profileImage,
  profileName,
  textSnippet,
  conversationId,
  isVisible,
  onClose,
  isMobile,
}: MessageSnackbarProps) {
  const { theme } = useAppContext(); // Get theme from context

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(onClose, 3000); // Auto-hide after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <Link
      to={`/messages/${conversationId}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <SnackbarContainer
        $isVisible={isVisible}
        $isMobile={isMobile}
        $theme={theme}
      >
        <ProfileImage src={profileImage} alt="Profile" />
        <MessageContent>
          <ProfileName>{profileName}</ProfileName>
          {/* <TextSnippet>{textSnippet}</TextSnippet> */}
          <TextSnippet>Has sent you a message</TextSnippet>
        </MessageContent>
        <IconContainer>
          <MessageIcon />
          <ArrowIcon />
        </IconContainer>
      </SnackbarContainer>
    </Link>
  );
}
