import React, { useEffect } from "react";
import { styled, keyframes } from "styled-components";

// Keyframes for the new slide-in and slide-out animations
const slideIn = keyframes`
  0% {
    transform: translateX(-150%); /* Start way off the screen */
    opacity: 0;
  }
  80% {
    transform: translateX(-50%); /* Overshoot a bit */
    opacity: 1;
  }
  100% {
    transform: translateX(-50%); /* Center the element */
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(-50%); /* Start at the centered position */
    opacity: 1;
  }
  20% {
    transform: translateX(-50%);
    opacity: 1;
  }
  100% {
    transform: translateX(150%); /* Move out of the screen to the right */
    opacity: 0;
  }
`;

// Styled component for the snackbar with transient props ($severity, $isVisible, and $isMobile)
const SnackbarContainer = styled.div<{ $severity: string; $isVisible: boolean; $isMobile: boolean | undefined }>`
  position: fixed;
  bottom: ${({ $isMobile }) => ($isMobile ? '20px' : '40px')};
  left: 50%; /* Center relative to the viewport */
  transform: translateX(-50%); /* Translate itself by 50% of its width to center it */
  width: ${({ $isMobile }) => ($isMobile ? '90%' : '30%')};
  max-width: 500px; /* Set a max width for large screens */
  background-color: ${({ $severity }) =>
    $severity === "success" ? "#4caf50" :
    $severity === "info" ? "#2196f3" :
    $severity === "warning" ? "#ff9800" :
    $severity === "error" ? "#f44336" : "#333"};
  color: white;
  padding: ${({ $isMobile }) => ($isMobile ? '12px' : '16px')};
  border-radius: ${({ $isMobile }) => ($isMobile ? '4px' : '8px')};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: ${({ $isVisible }) => $isVisible ? slideIn : slideOut} 0.8s cubic-bezier(0.25, 1, 0.5, 1) forwards; /* Adjust timing and easing */
  text-align: center;
`;

export default function CustomSnackbar({ message, severity, isVisible, onClose, duration, isMobile }: {
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error';
  isVisible: boolean;
  onClose: () => void;
  duration?: number;
  isMobile: boolean | undefined;
}) {
  useEffect(() => {
    if (isVisible) {
      // Auto-hide the snackbar after `duration` milliseconds
      const timer = setTimeout(onClose, duration ?? 3000);
      return () => clearTimeout(timer); // Clear timeout if component unmounts
    }
  }, [isVisible, duration, onClose]);

  return (
    <SnackbarContainer $severity={severity} $isVisible={isVisible} $isMobile={isMobile}>
      {message}
    </SnackbarContainer>
  );
}
