import { createContext, useContext, useState, ReactNode } from 'react';
import CustomSnackbar from '~/ui/pop-up-modals/notification-snackbar';
import MessageSnackbar from '~/ui/pop-up-modals/message-snackbar';
import { useAppContext } from '~/utils/app-content-provider'; // Import useAppContext

interface SnackbarContextType {
  showSnackbar: (message: string, severity: 'success' | 'info' | 'warning' | 'error', duration?: number) => void;
  showMessageSnackbar: (profileImage: string, profileName: string, textSnippet: string, conversationId: string) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

interface SnackbarProviderProps {
  children: ReactNode;
}

export default function SnackbarProvider({ children }: SnackbarProviderProps) {
  const { isUserMobile } = useAppContext();

  // State for CustomSnackbar
  const [snackbar, setSnackbar] = useState<{
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
    isVisible: boolean;
    duration?: number;
  }>({
    message: '',
    severity: 'info',
    isVisible: false,
    // duration: 3000,
  });

  // State for MessageSnackbar
  const [messageSnackbar, setMessageSnackbar] = useState<{
    profileImage: string;
    profileName: string;
    textSnippet: string;
    conversationId: string;
    isVisible: boolean;
  }>({
    profileImage: '',
    profileName: '',
    textSnippet: '',
    conversationId: '',
    isVisible: false,
  });

  // Show a CustomSnackbar
  const showSnackbar = (message: string, severity: 'success' | 'info' | 'warning' | 'error', duration?: number) => {
    // Hide the message snackbar if it's visible
    setMessageSnackbar((prev) => ({ ...prev, isVisible: false }));

    // Show the custom snackbar
    setSnackbar({ message, severity, isVisible: true, duration });
  };

  // Show a MessageSnackbar
  const showMessageSnackbar = (profileImage: string, profileName: string, textSnippet: string, conversationId: string) => {
    // Hide the custom snackbar if it's visible
    setSnackbar((prev) => ({ ...prev, isVisible: false }));

    // Show the message snackbar
    setMessageSnackbar({ profileImage, profileName, textSnippet, conversationId, isVisible: true });
  };

  // Close CustomSnackbar
  const handleClose = () => {
    setSnackbar({ ...snackbar, isVisible: false });
  };

  // Close MessageSnackbar
  const handleMessageClose = () => {
    setMessageSnackbar({ ...messageSnackbar, isVisible: false });
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, showMessageSnackbar }}>
      {children}
      {/* Render CustomSnackbar only if isVisible is true */}
      {snackbar.isVisible && (
        <CustomSnackbar
          message={snackbar.message}
          severity={snackbar.severity}
          isVisible={snackbar.isVisible}
          onClose={handleClose}
          duration={snackbar.duration}
          isMobile={isUserMobile}
        />
      )}
      {/* Render MessageSnackbar only if isVisible is true */}
      {messageSnackbar.isVisible && (
        <MessageSnackbar
          profileImage={messageSnackbar.profileImage}
          profileName={messageSnackbar.profileName}
          textSnippet={messageSnackbar.textSnippet}
          conversationId={messageSnackbar.conversationId}
          isVisible={messageSnackbar.isVisible}
          onClose={handleMessageClose}
          isMobile={isUserMobile}
        />
      )}
    </SnackbarContext.Provider>
  );
}

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
